<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{ participant ? participant.fullName + "'s" : "My" }} Accounts</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Search Accounts"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'myCompanyClaimsWithId', params: { id: item.organization.id } }">
            View Claims
          </router-link>
        </template>
        <template v-slot:item.organization.effective="{ item }">
          <span :inner-html.prop="item.organization | showActive"></span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MyAccounts",
  metaInfo: {
    title: "My Accounts"
  },
  data() {
    return {
      participantId: undefined,
      participant: undefined,

      search: "",

      loading: false,
      items: [],
      headers: [
        {
          value: "actions",
          text: "Actions"
        },
        {
          value: "organization.organizationKey",
          text: "Sap Account #",
          sortable: true
        },
        {
          value: "organization.name",
          text: "Name",
          sortable: true
        },
        {
          value: "organization.dba",
          text: "DBA",
          sortable: true
        },
        {
          value: "organization.organizationType.name",
          text: "Type",
          sortable: true
        },
        {
          value: "organization.effective",
          text: "Status",
          align: "center",
          sortable: false
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let filters = {};
      if (this.selectedParticipant.participantType.participantTypeKey == "300" && this.participantId) {
        filters.relatedParticipant = { id: this.participantId };
      } else {
        filters.relatedParticipant = { id: this.selectedParticipant.id };
      }
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      let sortByName = "sort";
      if (sortBy && sortBy.length > 0 && sortBy[0].includes("organization.")) {
        sortByName = "nestedSort";
      }
      this.$api
        .post(
          "/api/relatedParticipants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&" +
                sortByName +
                "=" +
                sortBy[0] +
                "," +
                ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchParticipant() {
      return this.$api.get("/api/participants/" + this.participantId).then(({ data }) => {
        this.participant = data;
      });
    }
  },
  mounted() {
    this.participantId = this.$route.params.id;
    if (this.participantId) {
      this.fetchParticipant();
    }
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "My Accounts" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
